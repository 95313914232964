export const useTimedAsyncData = async (key: string, fetcher: () => Promise<any>) => {
  const event = useRequestEvent();
  const start = performance.now();

  const result = await useAsyncData(key, fetcher);

  const end = performance.now();
  const duration = end - start;

  const existingHeader = event && event.node.res.getHeader("Server-Timing");
  const newTiming = `${key};dur=${duration}`;

  // Append new timing to existing header or set new header
  if (existingHeader) {
    event.node.res.setHeader("Server-Timing", `${existingHeader}, ${newTiming}`);
  } else {
    event && event.node.res.setHeader("Server-Timing", newTiming);
  }

  return result;
};
